<template>
    <div class="bbb">
      <van-nav-bar
        title="药品详情"
        left-arrow
        @click-left="onClickLeft"
        />
        <div id="Interrogation" class="drugs_details time_slot">
          <van-swipe :autoplay="3000" :height="250" >
            <van-swipe-item v-for="(item,index) of info.imgurl" :default="item"
              ></van-swipe-item
            >
          </van-swipe>
          <div class="drugs_content">
            <div style="color: #F56C6C; font-size: 30px; font-weight: 600;" v-if="Drugs.price">
              ￥{{ Drugs.price  == 'None' ? 0 : Drugs.price}}
            </div>
            <div class="title">
              {{ info.name }}
            </div>
            <div class="yongyao" style="color: #c1c1c1;" v-if="info.otc == '1'">
              <span class="chufangyao_text">问诊药</span>须凭问诊在药师指导下购买和使用
            </div>
            <div class="yongyao">
              <span class="title">适应症：</span>
              <span class="text" style="padding: 0;">{{ info.indication == 'None' ? '' : info.indication}}</span>
            </div>
            
            <div class="yongyao">
              <span class="title">规格：</span>
              <span class="text" style="padding: 0;">{{ info.specs == 'None' ? '' : info.specs }}</span>
            </div>
          </div>
          <div class="drugs_content">
            <div class="title">商品介绍</div>
            <div class="yongyao">
              <span class="title" v-if="info.dosage_txt !== 'None' && info.dosage_txt !== ''">用法：</span>
            </div>
            <div class="yongyao text">
              <div v-if="info.dosage_txt == 'None' || info.dosage_txt == ''">
              </div>
              <div v-else>
                <p  v-for="(item,index ) of sentencesWithLineBreaks(info.dosage_txt)">{{ item }}</p>
              </div>
            </div>
            <div class="yongyao">
              <span class="title" v-if="info.attentions !== 'None' && info.attentions !== ''">注意事项：</span>
            </div>
            <div class="yongyao text">
              <div v-if="info.attentions == 'None' || info.attentions == ''"></div>
              <div v-else><p v-for="(item,index ) of sentencesWithLineBreaks(info.attentions)">{{ item }}</p></div>
            </div>
            <div class="yongyao">
             <span class="title" v-if="info.constituents !== 'None'  && info.attentions !== ''"> 成分：</span>
            </div>
            <div class="yongyao text" >
              <div v-if="info.constituents == 'None' || info.attentions == ''"></div>
              <div v-else>
                <p v-for="(item,index ) of sentencesWithLineBreaks(info.constituents)">{{ item }}</p>
              </div>
            </div>
          </div>
          <div class="m-top">
              <van-button @click="onClickLeft" color="linear-gradient(to right, #02d3d9, #09e0c0)" type="primary" block>返回</van-button>
          </div>
     
      </div>
      
    </div>
  </template>
  <script>
  import footer_ from '../components/footer.vue'
  import base from './api/base.js'
  export default {
    components: {
      footer_,
    },
    data() {
      return {
        swipe:[],
        info:{},
        Drugs:{}
      }
    },
    methods: {
      onClickLeft(){
        this.$router.back(-1)
      },
      sentencesWithLineBreaks(text){
        if(text){
          return text.split("。").filter(Boolean).map(sentence => sentence.trim() + "。");
        } 
      },
      loadList(){
        let params = {
          search_db:'Drugs',
          query_type:`first`,
        }
        let data = {
          query:[
            {
              fild:{id:this.$route.query.id},
              type:'=',
              condition:'&&'
            }
          ],
          tables_name:[
            {
              name:'PublicDrugs',
              table_name:'PublicDrugs',
              my_fild:{id:'publicdrugs_id'},
            }
          ]
        }
        this.$api.article.query2(params,data).then(res => {
          res.data.result.PublicDrugs[0]['imgurl']  = []
          res.data.result.PublicDrugs[0]['imgurl'].push(`${base.weburl}static/img/${res.data.result.PublicDrugs[0].img_url}`)
          this.info = res.data.result.PublicDrugs[0]
          this.Drugs = res.data.result
        })
      },
    },
    created() {
      this.loadList()
    },
    mounted(){
      

    }
  }
  </script>
  <style scopde lang="less">
  .drugs_details .text{
    padding: 0 20px;
    color: #909399 !important;
    letter-spacing: 1px
  }
  .drugs_content{
    margin-top: 15px;
    background: #fff;
    border-radius: 6px;
    padding: 10px;
  }
  .title {
    font-weight: 500;
    font-size: 15px !important;
  }
  .yongyao{
    font-size: 13px;
    margin-top: 10px;
    
  }
  .chufangyao_text{
    background: #f0fbf7;
    color: #2bbda8;
    display: inline-block;
    padding: 1px;
  }
  </style>